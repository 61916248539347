/* Form */
.ant-form-item {
  margin-bottom: 24px;
}

.ant-form-item-label {
  > label {
    height: 40px;
  }
}

.ant-layout-header {
  box-shadow: 0 0 4px -4px fade(#000000, 08%);
  padding: 0 32px;
  line-height: 1;
  position: relative;
  z-index: 10;
  height: 72px;
  background: #fff;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ant-table-thead > tr > th {
  font-weight: 550;
}

.ant-table-thead {
  .ant-table-cell {
    * {
      white-space: nowrap;
    }
  }
}
.ant-table-tbody > tr > td {
  padding: 6px 16px;
}
.ant-menu-horizontal:not(.ant-menu-dark) {
  .ant-menu-item:hover::after,
  .ant-menu-submenu:hover::after,
  .ant-menu-item-active::after,
  .ant-menu-submenu-active::after,
  .ant-menu-item-open::after,
  .ant-menu-submenu-open::after,
  .ant-menu-item-selected::after,
  .ant-menu-submenu-selected::after {
    border-bottom: 2px solid var(--accent-turquoise) !important;
  }
}
.ant-layout-sider-children {
  background-color: #ffffff;
}
.ant-menu {
  padding: 0 5px;
  font-weight: 600;
  font-size: 13px;
  .ant-menu-item {
    margin-top: 0px;
    padding: 5px 0;
    display: flex;
    height: auto !important;
    line-height: auto !important;
  }
  .ant-menu-item-selected {
    background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
    color: black;
    &::after {
      display: none;
    }
  }
  .ant-menu-sub {
    padding: 10px 0;
  }
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 10px 0;
}
.ant-layout {
  position: relative;
  background: none;
}

/* Modal */

.anticon-close-circle {
  color: var(--error) !important;
}
.anticon-check-circle {
  color: var(--success) !important;
}
.anticon-info-circle {
  color: var(--accent-blue) !important;
}

.ant-form-item-explain {
  min-height: 1px !important;
  font-style: italic !important;
  text-align: right;
  position: absolute;
  bottom: -16px;
  right: 12px;
  line-height: 1;
}

.ant-form-item-explain-error {
  color: var(--error) !important;
  font-size: 11px;
}
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border-color: #5f5f5f26 !important;
}

.ant-input-disabled {
  color: #000000 !important;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border: none;
}

.ant-input:-webkit-autofill {
  -webkit-text-fill-color: var(--black-color);
  transition: background-color 1000s ease-in-out 0s;
}

.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  border-color: #5f5f5f26 !important;
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  border-color: #5f5f5f26 !important;
}

.ant-select-selection-item,
.ant-select-selection-placeholder,
.ant-select-selection-search-input {
  // line-height: 40px !important;
}

.ant-table-content {
  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #777;
  }
  &::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    background: #555;
  }
}

/* Checkbox */
.checkbox-group-column {
  display: flex;
  flex-direction: column;
  * {
    margin-left: 0px !important;
  }
  > .ant-checkbox-wrapper:not(:first-child) {
    margin-top: 6px;
  }
}

/* Description */
.ant-descriptions {
  margin-bottom: 20px;
  .ant-descriptions-item {
    padding-bottom: 0px;
  }
  .ant-descriptions-item-label {
    color: var(--primary-color) !important;
    font-weight: bold;
    font-size: 12px;
  }
}

.ant-input-number-input-wrap {
  width: 100%;
  .ant-input-number-input {
    padding: 0 !important;
  }
}

// card

.ant-card {
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
  border-radius: 4px;
  .ant-card-head-title {
    font-weight: 700;
  }
}

// statistic
.ant-statistic-content-value {
  font-weight: bold;
  color: #fff;
}

.ant-drawer-body {
}

.ant-btn-primary {
  background-color: #fece00;
  border-color: #fece00;
  color: black;
}