@import 'utilities.less';
@import 'override.less';
@import 'variable.less';
@import 'lib-override.less';
@import '../fonts/Roboto/styles.css';
@import '../fonts/AzoSans/styles.css';

body {
  margin: 0;
  font-family: 'NoirPro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.icon-middle path {
  width: 24px;
}

.customBtnCommon {
  background: var(--primary-color) !important;
  color: var(--black-color) !important;
  border: 2px solid var(--primary-color) !important;
}

.primaryText {
  color: var(--primary-color) !important;
}

.auto-width {
  width: auto !important;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ql-snow {
  .ql-picker {
    &.ql-size {
      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
          font-size: attr(data-value) !important;
        }
      }
    }
  }
}

.ql-size-8px {
  font-size: 8px;
}

.ql-size-9px {
  font-size: 9px;
}

.ql-size-10px {
  font-size: 10px;
}

.ql-size-12px {
  font-size: 12px;
}

.ql-size-14px {
  font-size: 14px;
}

.ql-size-16px {
  font-size: 16px;
}

.ql-size-20px {
  font-size: 20px;
}

.ql-size-24px {
  font-size: 24px;
}

.ql-size-32px {
  font-size: 32px;
}

.ql-size-42px {
  font-size: 42px;
}

.ql-size-54px {
  font-size: 54px;
}

.ql-size-68px {
  font-size: 68px;
}

.ql-size-84px {
  font-size: 84px;
}

.ql-size-98px {
  font-size: 98px;
}

.ant-modal-wrap {
  z-index: 86;
}

.ant-modal-mask {
  z-index: 85;
}