.button {
  padding: 8px 16px !important;
  p {
    font-size: 12px;
    background-color: var(--turquoise-dark);
    color: #fff;
    border-radius: 99px;
    margin-bottom: 0;
    padding: 5px 8px;
  }
}

.header {
  box-shadow: 0 0 4px -4px fade(#000000, 08%);
  text-align: center;
  // margin: 24px 0 12px;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(173, 173, 173, 0.2);
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  * {
    font-size: 24px;
  }
}

.boxIcon {
  background-color: #fbf4cd;
  border-radius: 3px;
  width: 35px !important;
  line-height: 35px !important;
  height: 35px !important;
  min-width: 35px !important;
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.boxIconCollapse {
  margin-right: 20px;
  :global &.ant-menu-title-content {
    margin-left: 0;
  }
}

.slide {
  overflow: auto;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  :global .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
  
}

.menu {
  overflow-x: hidden;
  height: calc(100vh - 172px);
  &::-webkit-scrollbar {
    width: 6px;
    /* This is more usable for users trying to click it. */
    background-color: #fff;
    -webkit-border-radius: 100px;
  }
  /* hover effect for both scrollbar area, and scrollbar 'thumb' */
  &::-webkit-scrollbar:hover {
    // background-color: #fece00;
  }
  
  /* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
  &::-webkit-scrollbar-thumb:vertical {
    /* This is the EXACT color of Mac OS scrollbars.
       Yes, I pulled out digital color meter */
    background: #fece00;
    -webkit-border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb:vertical:active {
    // background: #fff; /* Some darker color when you click it */
    -webkit-border-radius: 100px;
  }
}

.logout {
  display: flex;
  padding: 29px;
  align-items: center;
  height: 100px;
  border-top: 1px solid rgba(173, 173, 173, 0.2);
  font-weight: bold;
  cursor: pointer;
}

.containerProviders {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 0;
}

.boxProvider {
  display: flex;
  font-size: 16px;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  padding: 10px;
  color: #fff;
  cursor: pointer;

  img {
    object-fit: contain;
  }

  :first-child {
    flex: 1;
  }
}

.boxWalletConnect {
  background-color: #115db9;
}

.boxMetamask {
  background-color: #f47a2e;
}
