:root {
  /* Colors: */
  --turquoise-dark: #000000;
  --accent-turquoise: #000000;
  --cyan-light: #000000;
  --accent-blue: #4192ff;
  --tint-2: #eeeeee;
  --success: #12cea3;
  --error: #ff4d4f;
  --primary-color: #000000;
  --black-color: #000000;
  // #ff557c !important
  /* Sizes: */
  --nav-bar-height: 75px;
  --surface-padding: 23px 24px;
  --background-dark: #0f0f0f;
  --background-light: #40454f;

  --text-gray: #707a83;
  --text-blue: #2081e2;
  /* Fonts */
  --avenir: 'Avenir';
}
