.root {
  border-radius: 8px !important;
  padding: 12px 24px;
  height: 40px !important;
  line-height: 1 !important;
  font-weight: 700;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    background-color: #9aa3a7 !important;
    color: #fff !important;
    border: 2px solid #9aa3a7 !important;
  }
}

.default {
  color: var(--primary-color);
}

.primary {
  border: none !important;
  // background: var(--primary-color) !important;
  color: #ffffff !important;
}

.text {
  border: none !important;
  background: none !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

.secondary {
  border: none !important;
  background: var(--accent-turquoise) !important;
  color: #ffffff !important;
  &:hover {
    background: #ffffff !important;
    color: var(--accent-turquoise) !important;
  }
}

.info {
  border: none !important;
  background: var(--accent-blue) !important;
  color: #ffffff !important;
  &:hover {
    background: #ffffff !important;
    color: var(--accent-blue) !important;
  }
}

.link {
  border: none !important;
  background: transparent !important;
  height: 0 !important;
  // color: #ffffff !important;
  &:disabled {
    background-color: #9aa3a7 !important;
    color: gray !important;
    border: none !important;
  }
}

.success {
  background: var(--success) !important;
  border: none;
  color: #09625d !important;

  &:hover {
    color: #fff !important;
  }
}

.error {
  border: none !important;
  background: var(--error) !important;
  color: #fff !important;
  &:hover {
    color: var(--error) !important;
    background: #fff !important;
  }
}

.large {
  height: 58px !important;
}

.large span {
  font-size: 22px !important;
  font-weight: 500 !important;
}

.small {
  height: 20px !important;
  padding: 2px 12px 3px 12px !important;
  border-width: 1px !important;
}

.small span {
  font-size: 12px !important;
  font-weight: normal !important;
  line-height: 19px !important;
}

.default.small span {
  line-height: 12px !important;
}

.root[ant-click-animating-without-extra-node='true']::after {
  display: none;
}
