.menu svg {
  font-size: 20px !important;
}

.content {
  padding: 24px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: #f5f5f5;
  .full-scroll & {
    overflow-x: inherit;
  }
}

.header {
  margin-left: auto;
}

.root {
  
  .avatar {
    text-align: center;
    margin: 24px 0 12px;
  }
}